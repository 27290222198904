<template>
  <div class="vm-section vm-core d-flex align-center">
    <v-container class="text-center">
      <v-row class="justify-center">
        <v-col class="xs-12 sm-5 md-3">
          <h1>What is the Vuma Fibre Business?</h1>
          <p>
            Vuma Fibre Business is a scalable, cost-effective fibre solution,
            designed to meet the daily demands and needs of businesses across
            South Africa.
          </p>
          <p>
            We build last-mile, open-access fibre networks to business premises
            and, through our Internet Service Provider partners, provide
            businesses with a choice of world-class internet services to suit
            their data capacity and business continuity needs.
          </p>
          <v-btn 
            @click="scroll" 
            rounded 
            outlined 
            class="pink-outline move-to"
            >Enquire now</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  methods: {
    scroll() {
      if (screen.width < 600) {
        window.scroll({
          top: 1100,
          behavior: "smooth",
        });
      } else {
        window.scroll({
          top: 1800,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>