<template>
  <div id="high-speed">
    <v-card class="tab-card vm-tabs-product elevation-0">
      <v-tabs 
        v-model="tab" 
        dark 
        grow
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.activetab"
          v-on:click="activetab = tab.activetab"
        >
          {{ tab.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="vm-core-tabs">
        <v-tab-item v-for="tab in tabData" :key="tab.key">
          <div v-if="activetab === 1" :class="classProperty">
            <v-container>
              <v-row>
                <v-col class="col-12 col-sm-5 col-md-5">
                  <h2 v-if="activetab === 1" class="text-white">
                    {{ tab.title }}
                  </h2>
                  <p
                    class="vm-tab-content text-white"
                    style="width: 100%; max-width: 460px;"
                  >
                    {{ tab.description }}
                  </p>
                  <v-btn
                    @click="scroll"
                    data-section="vm-coverage-wrap"
                    rounded
                    outlined
                    class="enquire-btn move-to btn-white"
                  >
                    {{ enquireButtonText }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <div
              class="vm-tab-bg"
              :style="{
                backgroundImage: `${tab.backgroundImage}`,
              }"
            ></div>
          </div>
          <div v-if="activetab === 2" :class="classProperty">
            <v-container>
              <v-row>
                <v-col class="col-12 col-sm-5 col-md-5">
                  <h2 class="text-white">{{ tab.title }}</h2>
                  <p
                    class="vm-tab-content text-white"
                    style="width: 100%; max-width: 460px;"
                  >
                    {{ tab.description }}
                  </p>
                  <v-btn
                    @click="scroll"
                    data-section="vm-coverage-wrap"
                    rounded
                    outlined
                    class="enquire-btn move-to btn-white"
                  >
                    {{ enquireButtonText }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <div
              class="vm-tab-bg"
              :style="{
                backgroundImage: `${tab.backgroundImage}`,
              }"
            ></div>
          </div>
          <div v-if="activetab === 3" :class="classProperty">
            <v-container>
              <v-row>
                <v-col class="col-12 col-sm-5 col-md-5">
                  <h2 class="text-white">{{ tab.title }}</h2>
                  <p
                    class="vm-tab-content text-white"
                    style="width: 100%; max-width: 460px;"
                  >
                    {{ tab.description }}
                  </p>
                  <v-btn
                    @click="scroll"
                    data-section="vm-coverage-wrap"
                    rounded
                    outlined
                    class="enquire-btn move-to btn-white"
                  >
                    {{ enquireButtonText }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <div
              class="vm-tab-bg"
              :style="{
                backgroundImage: `${tab.backgroundImage}`,
              }"
            ></div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: null,
      searched: false,
      activetab: 1,
      enquireButtonText: "Enquire now",
      classProperty: "vm-section vm-tab-items d-flex align-center",
      tabData: [
        {
          key: "tab-core-entertain",
          description:
            "With speeds of up to 1Gbps, connect everyone - and their devices - quickly and efficiently. Enjoy the benefits of HD video conferencing, voice, and cloud applications with an internet connection that’s scalable to your business needs.",
          title: "Do more with fast internet",
          backgroundImage:
            "url(" +
            require(`@/assets/images/products/BusinessFibreProduct/tabs/business-page-tenants.jpg`) +
            ")",
        },
        {
          key: "tab-core-connect",
          description:
            "Connect your building to our open access fibre infrastructure and provide your tenants will have access to the best broadband services from some of the best local and International Internet Service Providers.",
          title: "More desirable property and low tenant turnover",
          backgroundImage:
            "url(" +
            require(`@/assets/images/products/BusinessFibreProduct/tabs/business-page-landlords.jpg`) +
            ")",
        },
        {
          key: "tab-core-choose",
          description:
            "Use our fibre infrastructure to offer your world-class business services quickly and cost effectively to potential clients that operate from our connected fibre precincts.",
          title: "Lower cost, greater flexibility, and speed to market",
          backgroundImage:
            "url(" +
            require(`@/assets/images/products/BusinessFibreProduct/tabs/business-page-service-providers.jpg`) +
            ")",
        },
      ],
      tabs: [
        {
          activetab: 1,
          title: "Tenants",
        },
        {
          activetab: 2,
          title: "Landlords",
        },
        {
          activetab: 3,
          title: "Service Providers",
        },
      ],
    };
  },
  methods: {
    scroll() {
      if (screen.width < 600) {
        window.scroll({
          top: 1150,
          behavior: "smooth",
        });
      } else {
        window.scroll({
          top: 1800,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.vm-tabs-product {
  .text-white {
    color: #fff;
  }
  .theme--dark.v-tabs {
    & > .v-tabs-bar {
      background-color: #ee008c;
      height: 85px;
    }
    .v-tab {
      font-size: 25px;
      font-family: "Core Sans R 35", "Roboto", sans-serif !important;
      text-transform: none;
      letter-spacing: 0.1em;
      width: 20%;
      color: #fff !important;
      &.v-tab--active {
        font-family: "Core Sans R 55", "Roboto", sans-serif !important;
        &::before {
          opacity: 0.24 !important;
        }
        &:after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-top: 20px solid #ee008c;
          position: absolute;
          bottom: -20px;
          left: 50%;
        }
      }
      &:hover::before {
        opacity: 0.24;
      }
    }
    .v-tabs-slider-wrapper {
      display: none;
    }
  }
  .vm-tab-items {
    color: #000;

    .vm-tab-bg {
      &:before {
        display: none;
      }
    }
  }

  .v-btn.btn-white {
    color: #fff;
    transition: all 0.2s ease-in;
    &:hover {
      background-color: #ff008e;
      border-color: #ff008e;
      color: #fff;
    }
  }
}

@media only screen and (max-width: 600px) {
  .tab-card.vm-tabs-product.v-card.v-sheet.theme--light {
    border-radius: 0;
    box-shadow: 0;
  }

  .enquire-btn,
  .enquire-btn span.v-btn__content {
    border-color: #ee008c;
    color: #ee008c;
  }

  .enquire-btn,
  .enquire-btn span.v-btn__content:hover {
    border-color: #ee008c;
    color: #fff;
  }
}
</style>